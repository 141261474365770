import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/crm-dla-firmy-it.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import { LiteYouTubeEmbed } from "react-lite-youtube-embed";
import Container from "../components/container";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    karta: file(relativePath: { eq: "blog-questy-karta-klienta.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    lista: file(relativePath: { eq: "blog-questy-definiowanie-kolumn.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        kalendarz: file(relativePath: { eq: "blog-questy-kalendarz.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
            szanse: file(relativePath: { eq: "blog-questy-szanse-sprzedazy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
              szansa: file(relativePath: { eq: "blog-questy-karta-szansy.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
              projekt: file(relativePath: { eq: "blog-questy-karta-projektu.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
                raporty: file(relativePath: { eq: "blog-questy-raporty.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
const CRMdlaFirmyIT = ({ data }) => {
  return (
    <Artykul
      title="CRM dla firmy IT: wsparcie sprzedaży i&nbsp;obsługi zleceń"
      articleImage={obrazekArtykulu}
      articleImageAlt="CRM IT system – wsparcie sprzedaży i obsługi zleceń"
      metaTitle="CRM dla firmy IT: wsparcie sprzedaży i obsługi zleceń"
      metaDescription="CRM IT system dla efektywnej sprzedaży i obsługi zleceń. | Wypróbuj bezpłatnie i zwiększ efektywność Twojej firmy! | Video 2022"
    >
      <p
        style={{
          paddingTop: 20,
        }}> Branżę informatyczną wyróżnia wiele specyficznych potrzeb w&nbsp;zakresie aktywności podejmowanych 
        przez działające w niej firmy. Program CRM dla firmy IT kompleksowo
        <strong> wspiera sprzedaż przedsiębiorstwa i&nbsp;obsługę poszczególnych zleceń</strong>, 
       uwzględniając wszystkie wymagania tego obszaru. Poznaj przetestowane narzędzia pracy i&nbsp;funkcjonalności 
       systemu zarządzania relacjami z&nbsp;klientami, które sami wykorzystujemy na co dzień, i&nbsp;które od wielu 
       lat z&nbsp;powodzeniem pozwalają naszym klientom przezwyciężać biznesowe trudności.
      </p>
      <div className="text-block">
        <LiteYouTubeEmbed
          poster="maxresdefault"
          id="eiY7L3TRmME"
          title="Efektywne wsparcie sprzedaży i obsługi zleceń za pośrednictwem CRM dla firmy IT"
        />
      </div>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >Skuteczne zarządzanie relacjami z klientami z bazą kontrahentów
      </h2>
      <p>
        <strong>Podstawową funkcję każdego oprogramowania CRM dla branży IT stanowi karta kontrahenta </strong>–
        gromadzi ona wszystkie kluczowe informacje o kontrahentach Twojej firmy. Oprócz możliwości wprowadzania 
        podstawowych danych (nazwa, NIP lub osoby i dane kontaktowe), system klasy CRM pozwala także dodać tam również 
        własne, indywidualne atrybuty. Co więcej, karta gromadząca dane klienta pozwala na umieszczanie wielu 
        rodzajów raportów, dotyczących wybranego przez Ciebie kontrahenta.
        </p>
               <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.karta]}
        alts={["Zarządzanie relacjami z klientami z bazą kontrahentów w systemie CRM dla firm IT"]}
      />

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
              Kolejną zaletą systemu CRM jest to, że bez przerwy masz<strong> dostęp do&nbsp;wszystkich wiadomości e-mail</strong>, które 
              zostały wysłane lub odebrane od osób reprezentujących Twojego klienta. System CRM pozwoli Ci na dodanie wszystkich 
              najważniejszych informacji związanych z aktywnymi umowami lub usługami. Znajdziesz w nim również historię 
              kluczowych w firmie IT dokumentów – od ofert, przez zamówienia, aż po faktury sprzedaży. System CRM umożliwia także 
              bezproblemowe, samodzielne rozbudowywanie karty kontrahenta o dodatkowe zakładki i moduły, 
              które mają szczególne znaczenie w Twoim biznesie.
            </p>
          </div>

          <div className="text-block" style={{
          paddingTop: 20,
        }}>
            <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.lista]}
        alts={["Lista kontrahentów system CRM dla firmy IT"]}
      />
          </div>
        </div>
      </Container>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz otrzymać wsparcie sprzedaży i obsługi zleceń w Twojej firmie IT?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas i zyskaj dostęp do bezpłatnej 30-dniowej wersji systemu CRM</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawdzić CRM w mojej firmie IT
            </Link>
          </div>
          </div>
    </section>

       <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >Planowanie i koordynacja zadań w programie CRM
      </h2>
      <p>
       Codzienna praca w Twojej firmie IT stanie się znacznie łatwiejsza dzięki systemowi CRM i możliwości 
       planowania zadań oraz wyświetlania ich w kalendarzu lub na szczegółowej liście zadań. Ponadto, system 
       CRM dopasowany do firmy IT automatycznie stworzy zdarzenie po wykonaniu określonego zadania, co stanowi 
       <strong> nieocenioną bazę danych o dotychczasowych kontaktach z klientami danej firmy</strong>. Na podstawie 
       zadań i zdarzeń możesz tworzyć również szereg różnych istotnych w branży IT raportów, które 
       umożliwiają<strong> obiektywną ocenę pracowników oraz ich działań</strong>.</p>

        <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.kalendarz]}
        alts={["CRM dla firmy IT - planowanie i koordynacja zadań w kalendarzu CRM"]}
      />

             <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
        }}
      >Uporządkowanie procesu sprzedaży i&nbsp;obsługa posprzedażowa
      </h2>
      
            <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p>
             Wdrożenie systemu CRM w firmie IT umożliwia kompleksowe uporządkowanie zarządzania procesem sprzedaży. 
             Do obszarów, które wspiera system CRM należy zarówno obszar pozyskiwania nowych szans sprzedaży, 
             jak i rozwój współpracy z&nbsp;obecnymi klientami firmy informatycznej.
             <strong> Bez&nbsp;problemu możesz stworzyć wszystkie interesujące Cię typy lejków sprzedażowych </strong>oraz&nbsp;zarządzać 
              ich poszczególnymi etapami i&nbsp;polami, które są dostępne na karcie szansy. W&nbsp;module 
              sprzedażowym profesjonalnego systemu CRM z&nbsp;łatwością przeanalizujesz wybrane dane i&nbsp;będziesz w&nbsp;stanie wyciągnąć z&nbsp;nich 
              wnioski, które pozwolą Ci&nbsp;na<strong> usprawnienie działu handlowego i&nbsp;procesu obsługi klienta</strong>.
            </p>
          </div>

          <div className="text-block" style={{
          paddingTop: 20,
        }}>
            <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.szanse]}
        alts={["Szanse sprzedaży w systemie CRM dla firmy IT"]}
      />
          </div>
        </div>
      </Container>

       <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.szansa]}
        alts={["Karta szansy sprzedaży w systemie CRM dla branży informatycznej"]}
      />

      <p>Wraz ze wsparciem sprzedaży w&nbsp;przedsiębiorstwie, system CRM online dla firmy IT pomaga również w&nbsp;porządkowaniu 
      <strong> wszystkich kwestii związanych z obsługą posprzedażową</strong>, takich jak<strong> wdrażanie, wsparcie oraz&nbsp;helpdesk</strong>. 
       Dzięki naszemu rozwiązaniu CRM z łatwością stworzysz typy zleceń charakterystyczne dla Twojej firmy i branży IT, zdefiniujesz ich poszczególne 
       fazy oraz skonfigurujesz pola, które będą dostępne dla Ciebie i&nbsp;dla Twoich pracowników. Wykorzystując system CRM, zlecenia wyświetlane 
       w&nbsp;widoku kanban bez problemu skonfigurujesz samodzielnie, porządkując w&nbsp;ten sposób każdy dzień pracy w Twojej firmie. Co więcej, CRM pozwala 
       powiązać je z&nbsp;bazą produktów lub usług, która jest obsługiwana przez Twoją firmę, dzięki czemu zbudujesz ich całą historię w systemie.
       </p>
       <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.projekt]}
        alts={["Zarządzanie projektem system CRM dla branży informatycznej"]}
      />
      <p>Do zadań systemu CRM należy również<strong> usprawnienie całej komunikacji firmy </strong>– zarówno tej 
      zewnętrznej (np. w procesie obsługi klienta), jak i wewnętrznej. Służą temu, między innymi, powiadomienia 
      SMS lub e-mail, które za pomocą systemu CRM zostaną wysłane automatycznie np. po zmianie fazy w zleceniu. 
      Co więcej, nawet w&nbsp;gotowym systemie CRM możemy wyposażyć Twoją firmę także w Panel Kontrahenta, który umożliwi Twoim 
      klientom rejestrowanie nowych zleceń i sprawdzanie statusu tych, które są realizowane w danym momencie.
      </p>
      <p>Kolejną zaletą systemu CRM dla branży IT jest <strong><Link to="/automatyzacja-sprzedazy/">automatyzacja powtarzalnych elementów procesu</Link></strong>, których realizacja jest 
      wyjątkowo czasochłonna. Wspólnie możemy zastanowić się, jak taki automatyzator wdrożyć w&nbsp;Twojej firmie, co pozwoli Twoim 
      pracownikom na uniknięcie wielu błędów oraz znaczną oszczędność czasu. Do procesów, w&nbsp;których automatyzacji może być 
      potrzebny system CRM należą na przykład rozliczenia umów SLA czy subskrypcyjnych, dotyczących klientów firmy.
      </p>

       <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >Raportowanie i integracja systemu CRM
      </h2>
      <p>
       Kolejną z funkcjonalności systemu CRM dla firmy IT wspierających sprzedaż i obsługę zleceń jest <strong><Link to="/raportowanie-sprzedazy/">szereg zestawień i raportów</Link></strong>. 
       Mogą one pokazywać wszystkie najistotniejsze dla Ciebie informacje w zarządzaniu firmą, np. rentowność aktualnej współpracy z klientami 
       lub obecne obciążenie poszczególnych zespołów przy zarządzaniu projektami.
       </p>
        <Lightbox
        style={{
          maxWidth: 600,
          margin: "0 auto",
        }}
        images={[data.raporty]}
        alts={["CRM dla firmy IT: raporty i zestawienia do analizy"]}
      />
      <p>Budujemy systemy CRM, które <strong><Link to="/integracja-crm-z-systemem-handlowym//">posiadają wiele gotowych integracji z&nbsp;innymi 
      rozwiązaniami</Link></strong> – między innymi z programami handlowo-magazynowymi. 
      W&nbsp;razie zaistnienia takiej potrzeby, w&nbsp;dedykowanym systemie CRM możemy również napisać integracje z&nbsp;systemami ERP wykorzystywanymi w&nbsp;Twojej firmie. 
      Nasz system CRM wyposażony jest także w&nbsp;moduł API, dzięki któremu wszelkie potrzebne integracje mogą być tworzone również przez zespół projektowy Twojej firmy.
      </p>
      <p>Niezawodny system CRM dla firmy IT umożliwia również indywidualne rozwijanie poszczególnych modułów, które są przez nas dostarczane. Dla przykładu, 
      możesz utworzyć indywidualne raporty, szablony czy też integracje, o&nbsp;których wspominaliśmy wcześniej.</p>
      <h2
        style={{
          textAlign: "left",
          paddingTop: 20,
          paddingBottom: 20,
        }}
      >Już teraz sprawdź rozwiązanie CRM dla branży IT!
      </h2>
      <p>W branży IT działamy już od ponad 20 lat, dzięki czemu posiadamy ogromne doświadczenie w projektowaniu i&nbsp;wdrażaniu rozwiązań 
      w&nbsp;zakresie sprzedaży i&nbsp;zarządzania procesami. Chętnie przekażemy naszą wiedzę i doświadczenie również Tobie, wyposażając Twoją 
      firmę w system zarządzania klientami przystosowany do specyfiki jej działalności i&nbsp;branży, w&nbsp;której się obraca.
      </p>
      <p>CRM dla firmy IT to rozwiązanie, które z powodzeniem usprawni każdy aspekt działalności w&nbsp;Twoim przedsiębiorstwie. 
      <strong><Link to="/kontakt/">Skontaktuj się z nami już teraz</Link></strong> i&nbsp;dowiedz się, w jaki sposób możemy zwiększyć efektywność Twojego biznesu.</p>

      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz otrzymać wsparcie sprzedaży i obsługi zleceń w Twojej firmie IT?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, by zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawdzić CRM w mojej firmie IT
            </Link>
          </div>
          </div>
    </section>


      <p>
        <strong>Zachęcamy do przeczytania również:</strong>
        <ul>
          <li>
            <Link to="/co-to-jest-crm/">Co to jest CRM? Wywiad z ekspertem [aktualizacja 2022]</Link>
          </li>
          <li>
            <Link to="/proces-sprzedazy/"> Wsparcie procesu sprzedaży - system CRM</Link>
          </li>
          <li>
            <Link to="/integracja-crm-z-systemem-handlowym/"> Integracja CRM z systemem handlowym</Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default CRMdlaFirmyIT;
